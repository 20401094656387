$mainBgColor: #F9FAFC !important;
$secondBgColor: #26262E !important;

$blackColor1: #212121 !important;
$blackColor2: #1F1F1F !important;
$blackColor3: #0A0F1F !important;
$blackColor4: #3EBF8F !important;
$blackColor5: #DEF0FC !important;

$primaryColor: #0180F6 !important;

$primaryColorWithAlpha: #1212121a !important;

$inputBgColor: #FFFFFF !important;
$inputTextColor: #565656 !important;
$inputBorderColor: #DDDFE3 !important;
$blackBtnBg: #121212 !important;
$redBtnBg: #FF5757 !important;
$bludColor: #0180F6 !important;

$borderColor1: #DDDFE3 !important;
$borderColor2: #E7E7E8 !important;
$borderColor3: #DDDFE3 !important;
$borderColor4: #CBD0DC !important;

$grayTextColor: #9E9E9E !important;
$grayTextColor2: #FCFCFC !important;
$grayTextColor3: #CFCFCF !important;
$grayTextColor4: #ABB4BA !important;
$grayTextColor5: #A9ACB4 !important;

$redGradient: linear-gradient(90deg, #ED2630 10%, #F54262 38%, #ED2630 97%);
$blueGradient: linear-gradient(90deg, #00BCFF 21%, #079CD7 51%, #00A1FF 100%);

.black-text {
    color: $blackColor1;
}

.primary-text {
    color: $primaryColor;
}

.white-text {
    color: white !important;
}

.gray-text {
    color: $grayTextColor;
}

.pointer {
    cursor: pointer;
}

.link-text {
    @extend .primary-text;

    @extend .pointer;
}

.primary-btn {
    color: white !important;
    background-color: $primaryColor;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.black-btn {
    color: white !important;
    background-color: $blackColor3;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.btn {
    border-radius: 12px;
    padding: 8px 16px;
    color: white;
    font-size: 14px;
    margin: 8px;
    cursor: pointer;
}

// export variables for using in JS files
:export {
    primaryColor: $primaryColor;
    primaryColorWithAlpha: $primaryColorWithAlpha;
    mainBgColor: $mainBgColor;
    secondBgColor: $secondBgColor;
    inputBgColor: $inputBgColor;
    inputBorderColor: $inputBorderColor;
    inputTextColor: $inputTextColor;
}
